import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";

// Utilities
import kebabCase from "lodash/kebabCase";

// Components
import { Link, graphql } from "gatsby";
import MeterLink from "../components/Services/meter-link";

const Meters = ({ data }) => {
  const { t } = useTranslation();
  const { edges } = data.postData;
  const group = data.tagData.group;

  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Kompatible Zähler")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Kompatible Zähler")}
      />
      <div>
        <div className="widget-area pb-70">
          <div className="container widget widget_tag_cloud">
            <h3 className="widget-title">{t("Tags")}</h3>

            <div className="tagcloud">
              {group.map((tag) => (
                <Link
                  key={Math.random()}
                  to={`/meters/${kebabCase(tag.fieldValue)}/`}
                >
                  <span className="post-count">
                    {" "}
                    {tag.fieldValue} ({tag.totalCount}){" "}
                  </span>
                </Link>
              ))}
              <Link to="/meters">Alle</Link>
            </div>
          </div>
        </div>

        <div className="blog-area">
          <div className="container">
            <div className="row">
              {edges.map(({ node }) => (
                <MeterLink key={node.id} meter={node} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

Meters.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              link: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Meters;

export const pageQuery = graphql`
  query ($tag: String, $language: String!) {
    tagData: allMarkdownRemark(
      limit: 2000
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/meters/)/" }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    allTagData: allMarkdownRemark(
      limit: 2000
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/meters/)/" }
      }
    ) {
      totalCount
    }
    postData: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___priority], order: ASC }
      filter: {
        frontmatter: { tags: { in: [$tag] }, language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/meters/)/" }
      }
    ) {
      totalCount
      edges {
        node {
          html
          excerpt(pruneLength: 250)
          frontmatter {
            title
            link
            priority
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 150, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
